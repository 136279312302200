.GameScreenSuperGirl {
  position: relative;
  background-color: #ffffff;

  .slide-view {
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    &.stopAnimation{
      span{
        animation-play-state: paused!important;
      }
      
    }
    &.layer0 {
      span {
        background-image: url("../images/cloudsSuperGirl.png");
        animation: animatedB 50s infinite linear;
        background-position: top center;
      }
    }
    &.layer1 {
      span {
        background-image: url("../images/buildings.png");
        animation: animatedB 10s infinite linear;
        background-position: top center;
        // background-size: 50% auto;
      }
    }
    // &.layer2 {
    //   span {
    //     background-image: url("../images/hills-05.png");
    //     animation: animatedB 250s infinite linear;
    //     // background-size: 100% 700px;
    //     // background-position: top center;
    //     //opacity: 0.8;
    //   }
    // }
    // &.layer3 {
    //   span {
    //     background-image: url("../images/hills-04.png");
    //     animation: animatedB 200s infinite linear;
    //     // background-size: 200% 500px;
    //     // opacity: 0.9;
    //   }
    // }
    // &.layer4 {
    //   span {
    //     background-image: url("../images/hills-03.png");
    //     animation: animatedB 150s infinite linear;
    //     background-position: top center;
    //     //background-size: 217% auto;
    //   }
    // }
    // &.layer5 {
    //   span {
    //     background-image: url("../images/hills-02.png");
    //     animation: animatedB 70s infinite linear;
    //   }
    // }
    // &.layer6 {
    //   span {
    //     background-image: url("../images/hills-01.png");
    //     animation: animatedB 30s infinite linear;
    //     //background-size: 230% auto;
    //   }
    // }
    span {
      height: 100%;
      display: block;

      background-size: contain;
      width: 1920px;
      background-size: 200% auto;
      background-position: bottom -153px left 0;
      background-repeat: repeat-x;
      animation: animatedB 12s infinite linear;
      // transition: all;
      // transition-delay: 12s;
      // transition-duration: 12s;
      // transition-timing-function: linear;
      // animation: animatedB;

      @keyframes animatedB {
        from {
          background-position: bottom -153px left 0;
        }
        100% {
          background-position: bottom -153px left 200%;
        }
      }
    }
  }

  .GameScreen-bg {
    position: relative;
    z-index: 2;
    background-size: contain;
    max-width: 100%;
    height: 600px;
    background-repeat: repeat-x;
    transition: all;
    @media (max-width: $macbook) {
      height: 450px;
    }

    @keyframes animatedBackground {
      from {
        background-position: bottom -153px left 0;
      }
      100% {
        background-position: bottom -153px right 200%;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      background: rgba(#000, 0.5);
      left: 0;
      right: 0;
    }

    .logo-bx {
      img {
        position: relative;
        z-index: 99;
      }
      // &:after {
      //   content: "";
      //   background-image: url("../images/logo-shape.png");
      //   position: absolute;
      //   left: 13px;
      //   top: -28px;
      //   width: 309px;
      //   height: 100%;
      //   background-repeat: no-repeat;
      //   opacity: 0.5;
      //   transform: rotate(-7deg);

      //   @media screen and (max-width: 991px) {
      //     left: 0px;
      //     top: -37px;

      //     transform: rotate(-4deg);
      //   }
      // }
    }
    @media screen and (max-width: 575px) {
      background-size: cover;
      height: 300px;
    }
    .games-objecs {
      bottom: 0;
      width: 100%;
      .obstacle-move {
        position: absolute;
        left: 0;
      }
      .mountain {
        animation: leftRight linear 5s infinite;
      }
      .aeroplane {
        animation: leftRight linear 10s infinite;
      }
      .bird {
        animation: leftRight linear 30s infinite;
      }
      @keyframes leftRight {
        from {
          left: 100%;
          //transform: translateY(100%);
        }
        to {
          right: 100%;
          margin-top: 80px;
          //transform: translateY(-80%);
        }
      }
    }
  }
  .score {
    position: absolute;
    top: 3%;
    right: 0;
    background-color: rgba(#fff, 0.5);
    color: gray;
    padding: 10px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 10px 19px;
    max-width: 325px;
    width: 100%;
    @media screen and (max-width: 767px) {
      padding: 8px 9px;
      max-width: 190px;
      width: 100%;
    }
    h3 {
      font-size: 18px;
      margin: 0px;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
      span {
        margin: 0px 10px;
      }
    }
  }
}

.character-list {
  .cartoon-list {
    padding: 90px;

    @media (max-width: $macbook) {
      padding: 45px 90px;
    }
    @media screen and (max-width: 575px) {
      padding: 30px 0px;
    }
    .cartoon-list-bx {
      .cartoon-list-img {
        width: 190px;
        max-width: 100%;
        height: 170px;
        border-radius: 20px;
        background-color: #dadada;
        display: flex;
        align-items: center;
        justify-content: center;

        &.cartoon-red {
          background-image: radial-gradient(white, #f66640);
        }
        &.cartoon-yellow {
          background-image: radial-gradient(white, #fefc29);
        }
        &.cartoon-green {
          background-image: radial-gradient(white, #4efa25);
        }
        &.cartoon-blue {
          background-image: radial-gradient(white, #38a3ff);
        }
        &.cartoon-purple {
          background-image: radial-gradient(white, #8757d3);
        }
        img {
          @media screen and (max-width: 575px) {
            width: 80px;
          }
        }
        @media screen and (max-width: 991px) {
          margin: 0 auto;
        }
      }
      .cartoon-txt {
        margin: 25px 0px;
        text-align: center;
        a {
          &:hover,
          &:active {
            color: rgb(61, 181, 255);
          }
        }
      }
      .cartoon-txt h3,
      a,
      p {
        font-size: 21px;
        font-weight: 400;
        color: #505050;
        @media screen and (max-width: 991px) {
          margin: 17px 0px;
          font-size: 16px;
        }
      }
    }
  }
  .footer-text {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 10px 30px;
    align-items: center;
    // .footer-logo{
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);

    // }
  }
  .contact-txt {
    text-align: center;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 16px;
    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }
}

.logo-bx {
  img {
    position: relative;
    z-index: 99;
    top: 15px;
    left: 35px;
    height: 35px;
    width: 102px;
  }
}
.end-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7c7;
  border-radius: 22px;
  box-shadow: 0px 0px 12px #484848;
  min-width: 470px;
  padding: 20px 60px;
  h3 {
    color: black;
    text-align: center;
    margin-bottom: 40px;
  }
  p {
    color: black;
    font-size: 22px;
    font-weight: 300;
    text-align: center;
  }
  .restart-btn {
    background-color: #3685ad;
    color: white;
    border: 0px;
    border-radius: 20px;
    font-size: 17px;
    font-weight: 600;
    max-width: 400px;
    width: 100%;
    padding: 10px 10px;
    min-width: 110px;
  }
  .end-btn {
    background-color: #bd4040;
    color: white;
    border: 0px;
    font-size: 17px;
    font-weight: 600;
    max-width: 100px;
    width: 100%;
    padding: 10px 0px;
    min-width: 110px;
  }
}

.game-modal {
  .modal-content {
    background-color: #f7f7f7c7;
    border-radius: 22px;
    box-shadow: 0px 0px 12px #484848;
    .modal-header {
      border: none;
      justify-content: center;
      .modal-title {
        color: black;
        text-align: center;
      }
    }
    .modal-body {
      text-align: center;
      p {
        color: black;
        font-size: 22px;
        font-weight: 300;
      }
    }
  }
}

.game-modal {
  .modal-content {
    background-color: #f7f7f7c7;
    border-radius: 22px;
    box-shadow: 0px 0px 12px #484848;
    .modal-header {
      border: none;
      justify-content: center;
      .modal-title {
        color: black;
        text-align: center;
      }
    }
    .modal-body {
      text-align: center;
      p {
        color: black;
        font-size: 22px;
        font-weight: 300;
      }
    }
  }
}
.contactForm {
  padding: 12px;
  .content {
    margin-bottom: 38px;
    h3 {
    }
    p {
      color: #909aa3;
      color: #909aa3;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }
  .form-group {
    position: relative;
    margin-bottom: 26px;
    label {
      background: #fff;
      padding: 0 6px;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      position: absolute;
      top: 0;
      left: 6px;
      line-height: 1;
      transform: translateY(-50%);
    }
  }
  textarea {
    min-height: 90px;
  }
  .form-control {
  }
  .btn {
    border-radius: 6px;
  }
}
.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: 0;
  padding: 4px;
  line-height: 1;
  img {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  // padding: 45px 90px;
  .runner-wrapper {
    width: 80%;
    margin: auto;
  }
  canvas {
    width: 100% !important;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .runner-wrapper {
    width: 80%;
    margin: auto;
  }
  canvas {
    width: 100% !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .runner-wrapper {
    width: 80%;
    margin: auto;
  }
  canvas {
    width: 100% !important;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1824px) {
  .runner-wrapper {
    width: 80%;
    margin: auto;
  }
  canvas {
    width: 100% !important;
  }
}