.startPage {
  .start-logo {
    position: relative;
    z-index: 99;
  }
  position: relative;
  background-color: none;
  .mountain-bg {
    background-image: url("../images/mountainLayer1.png");
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    h3 {
      font-size: 45px;
      color: #ffffff;
      margin-bottom: 42px;
      text-transform: capitalize;
      font-family: "Lobster", cursive;
      z-index: 99;
    }

    .game-btn {
      background-color: transparent;
      font-size: 24px;
      border: 0;
      text-transform: uppercase;
      text-shadow: 1px 1px 1px #656262;
      animation: crescendo 0.75s alternate infinite ease-in;
      color: #ffffff;
    }
    @keyframes crescendo {
      0% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1.5);
      }
    }
    .game-btn {
      img {
        width: 70px;
        margin: 30px 0px;
      }
    }
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;

      background-color: #0404047d;
    }
  }
}


  // .modal-content {
  //   background-color: transparent;
  //   border: none;
  //   .modal-header {
  //     border-radius: 40px !important;
  //     text-align: center;
  //     /* align-items: center; */
  //     justify-content: center;
  //     background-image: linear-gradient(182deg, #0a3149, #73c1e3);
  //     border: 1px solid #4d6c7f;
  //     box-shadow: 0px 0px 51px #00010163;
  //     font-size: 8px;
  //     padding: 30px 0px;
  //   }
  //   .modal-header {
  //     border-radius: 40px !important;
  //     text-align: center;
  //     /* align-items: center; */
  //     justify-content: center;
  //     background-image: linear-gradient(182deg, #0a3149, #73c1e3);
  //     border: 1px solid #4d6c7f;
  //     box-shadow: 0px 0px 20px #00010163;
  //     font-size: 8px;
  //     padding: 30px 0px;
  //     h5.modal-title {
  //       text-align: center;
  //       color: white;
  //       text-transform: uc;
  //       text-transform: capitalize;
  //       font-size: 8px;
  //       h3 {
  //         font-size: 23px;
  //         letter-spacing: 2px;
  //         text-shadow: 1px 1px 5px #081229;
  //       }
  //     }
  //   }
  // }
  .start-modal-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .start-modal-header {
      border-radius: 40px !important;
      text-align: center;
      /* align-items: center; */
      justify-content: center;
      // background-image: linear-gradient(182deg, #0a3149, #73c1e3);
      // border: 1px solid #4d6c7f;
      // box-shadow: 0px 0px 51px #00010163;
      font-size: 8px;
      // padding: 30px 60px;
      // @media(max-width: $macbook){
      //   padding: 25px 45px;
      // }
      h3{
        color: #fff;
        font-size: 35px;
        letter-spacing: 2px;
        // text-shadow: 1px 1px 5px #081229;
        text-transform: uc;
        text-transform: capitalize;
        @media(max-width: $macbook){
          font-size: 20px;
        }
      }
    }

  }


