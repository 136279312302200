.AliwonderGameScreen {
  position: relative;
  background-color: #d8f7ff;
 
  .slide-view {
   
    width: 3568px;
    height: 100%;
    position: absolute;
    z-index: 1;
      display: block;
      background-position: bottom 0 right 0;
      background-repeat: repeat-x;
      animation: animatednew 10s infinite linear;
    
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    &.stopAnimation{
      span{
        animation-play-state: paused!important;
      }
      
    }
    &.layer0 {
      span{
      background-image: url("../images/Background-house.jpg");
      background-size: 100% auto;
      animation: animatednew 30s infinite linear;
      background-position: top center;
      transition: 2s ease;
      animation-direction: reverse;
      
      }
    }
    &.layer1 {
      span {
        background-image: url("../images/aliwonder/cloudss.png");
        animation: animatednew 30s infinite linear;
        position: relative;
        top: -270px;
        transition: 2s ease;
        animation-direction: reverse;
        @media(max-width: $macbook){
          top: -200px;
        }
        @media(max-width:  $xlDesktop){
          top: -180px;
        
        }
     
      }
    }
    &.layer2 {
      span {
        background-image: url("../images/aliwonder/treesAndBushes.png");
        animation: animatednew 25s infinite linear;
        background-position: top center;
        transition: 2s ease; 
        position: relative;
        top: -145px;
        animation-direction: reverse;
        @media(max-width:  $macbook){
          top: -108px;

        }
        @media(max-width:  $xlDesktop){
          top: -84px;
        
        }
      
      }
    }
    &.layer3 {
      span {
        background-image: url("../images/aliwonder/homes.png");
        animation: animatednew 20s infinite linear;
        background-position: top center;
        transition: 2s ease;
        animation-direction: reverse;
      
      }
    }
   

      @keyframes animatednew {
        from {
          background-position: bottom 0 right 0;
        }
        100% {
          background-position: bottom 0 right 100%;
        }
      }
      @keyframes animatednew {
        from {
          background-position: bottom -153px left 0;
        }
        to{
          background-position: bottom -153px left 200%;
        }
      }
   

      span{
        height: 100%;
        display: block;
  
        background-size: contain;
        // width: 3568px;
        width: 100%;
        background-size: 200% auto;
        background-position: bottom 0 right 0;
        background-repeat: repeat-x;
        animation: animatednew 12s infinite linear;
        @keyframes animatednew {
          from {
            background-position: bottom 0 right 0;
          }
          100% {
            background-position: bottom 0 right 200%;
          }
        }
      }
  }

  .GameScreen-bg {
    position: relative;
    z-index: 2;
    background-size: contain;
    max-width: 100%;
    height: 600px;
    // background-repeat: repeat-x;
    transition: all;
    
    @media(max-width: $macbook){
      height: 450px;
    }
  }


}

.logo-bx {
  img {
    position: relative;
    z-index: 99;
  }
}
.score {
  position: absolute;
  top: 3%;
  right: 0;
  background-color: rgba(#fff, 0.5);
  color:gray;
  padding: 10px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 10px 19px;
  max-width: 325px;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 8px 9px;
    max-width: 190px;
    width: 100%;
  }
  h3 {
    font-size: 18px;
    margin: 0px;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
    span {
      margin: 0px 10px;
    }
  }
}