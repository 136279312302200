@font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir/AvenirLTStd-Black.otf");
    font-weight: 700;
  }
  @font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir/AvenirLTStd-Book.otf");
    font-weight: 500;
  }
  @font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir/AvenirLTStd-Roman.otf");
    font-weight: 600;
  }

@import "~bootstrap/scss/bootstrap";
@import "./variables";
@import "./style";
@import "./gameScreen";
@import "./startPage";
@import "./runningHomeGame";
@import "./gameScreenSuperGirl";

